import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Currency, curr } from "../../currencies";
import { Discount } from "../../types";

export enum PurchaseType
{
  Black,//0
  Elite,//1
  ReplacementBlack,//2
  ReplacementElite,//3
  NewMember//4
}
export enum PaymentType
{
  CreditCard,
  Paypal
}
export type Details =
  {
    shippingDetails: Shipping,
    billingDetails: Billing,
    paymentDetails: Payment
  }

export type Payment =
  {
    type: PaymentType,
    creditCard: string,
    expirationDate: string,
    securityCode: string
  }
export type Shipping =
  {
    firstName: string,
    lastName: string,
    email: string,
    street1: string,
    street2: string,
    stateProvince: string,
    city: string,
    country: string,
    zip: string,
    phone: string
  }

export type Billing = {
  firstName: string,
  lastName: string,
  email: string,
  street1: string,
  street2: string,
  stateProvince: string,
  city: string,
  country: string,
  zip: string,
  phone: string
}
export type Itemized = {
  [key: string]: { description: string, value: number }
}

export type Purchase =
  {
    subtotal: number,
    total: number,
    order: Black | Elite,
    details: Details
    tax: number
    itemized: Itemized
    discount: Discount
  }

export type Black =
  {
    orderNumber?: string,
    valid: boolean | undefined,
    submit: boolean,
    type: PurchaseType,
    fullName: string,
    company: string,
    logoAdd: boolean,
    blank: boolean,
    color: string,
    extra: number,
    logo: string[]
  }

export type Elite =
  {
    orderNumber?: string,
    valid: boolean | undefined,
    submit: boolean,
    type: PurchaseType,
    pCard: PCard[],
    npCard: PCard[],
    base: number,
    personalized: number,
    nonPersonalized: number
    extra: number,
    company: string,
    blank: boolean,
    color: string,
    logo: string[],
    reference?: string
  }
export type PCard =
  {
    qr?: string
    orderNumber: string,
    fullName: string,
    extra?: number
  }

export enum Colors
{
  Silver = 'Silver',
  Gold = 'Gold',
  White = 'White'
}

const taxRates = (region: string) =>
{
  return 0;
  /*switch (region)
  {
    case 'Alberta':
      return 0.05
    case 'British Columbia':
      return 0.12
    case 'Manitoba':
      return 0.12
    case 'New Brunswick':
      return 0.15
    case 'Newfoundland and Labrador':
      return 0.15
    case 'Northwest Territories':
      return 0.05
    case 'Nova Scotia':
      return 0.15
    case 'Nunavut':
      return 0.05
    case 'Ontario':
      return 0.13
    case 'Prince Edward Island':
      return 0.15
    case 'Québec':
      return 0.14975
    case 'Saskatchewan':
      return 0.11
    case 'Yukon':
      return 0.05
    default:
      return 0
  }*/
}

export function getColorData(color: string, currency: Currency)
{
  switch (color)
  {
    case Colors.Silver:
      return { value: curr['silver'][currency], description: Colors.Silver };
    case Colors.Gold:
      return { value: curr['gold'][currency], description: Colors.Gold }
    case Colors.White:
      return { value: curr['white'][currency], description: Colors.White };
    default:
      return { value: 0, description: '' }
  }
}


const cartSlice = createSlice({
  name: "cart",
  initialState: { details: { paymentDetails: {} as Payment, billingDetails: {}, shippingDetails: {} }, total: 0, order: {} as Elite | Black, discount: {} as Discount } as Purchase,
  reducers: {
    setOrder: (state, action: PayloadAction<Black | Elite>) =>
    {
      state.order = action.payload;
    },
    setDetails: (state, action: PayloadAction<Details>) =>
    {
      state.details = action.payload;
    },
    getTax: (state) =>
    {
      const discount = !isNaN(state.discount.totalDiscounted) ? state.discount.totalDiscounted : 0;
      state.tax = Math.round(((((state.subtotal - discount) * taxRates(state.details.shippingDetails.stateProvince))!) + Number.EPSILON) * 100) / 100;
    },
    setDiscount: (state, action: PayloadAction<Discount>) =>
    {

      state.discount = {
        code: action.payload.code,
        affiliateName: action.payload.affiliateName,
        totalDiscounted: Math.round(((state.subtotal * action.payload.percentDiscount) + Number.EPSILON) * 100) / 100,
        percentDiscount:
          action.payload.percentDiscount
      } as Discount
    },
    getSubTotal: (state, action: PayloadAction<Currency>) =>
    {
      switch (state.order.type)
      {
        case PurchaseType.Black:
          {
            let order = state.order as Black
            //let color = ((state.order.color === '' || state.order.color === Colors.White)) ? 0 : 5
            let color = getColorData(state.order.color, action.payload)

            state.subtotal = curr['blackBase'][action.payload] + order.extra * curr['blackExtra'][action.payload] + (order.logoAdd ? curr['blackLogo'][action.payload] : 0) + color.value;
            break;
          }
        case PurchaseType.Elite:
          {
            let order = state.order as Elite
            //let color = ((state.order.color === '' || state.order.color === Colors.White)) ? 0 : 5
            let color = getColorData(state.order.color, action.payload)

            state.subtotal = curr['eliteBaseUnit'][action.payload] * order.base + (order.base < 8 ? curr['eliteQuantityBase'][action.payload] - order.base * curr['eliteQuantityDiscount'][action.payload] : 0) + order.base * order.extra * curr['eliteExtra'][action.payload] + order.personalized * curr['elitePersonalized'][action.payload] + color.value * order.base;
            break;
          }
        case PurchaseType.ReplacementBlack:
          {

            state.subtotal = curr['blackReplacement'][action.payload] * state.order.extra;
            break;
          }
        case PurchaseType.ReplacementElite:
          {
            const order = state.order as Elite

            if (order.npCard.length > 0)
            {

              state.subtotal = order.npCard.reduce((p, c, i) => { return { extra: (p.extra ?? 0) + (c.extra ?? 0) } as PCard }).extra! * curr['eliteReplacement'][action.payload];
            }
            else
            {

              state.subtotal = curr['eliteReplacement'][action.payload]
            }
            break;
          }
        case PurchaseType.NewMember:
          {
            const order = state.order as Elite;
            let color = getColorData(state.order.color, action.payload)

            state.subtotal = curr['eliteBaseUnit'][action.payload] * order.base + order.personalized * curr['elitePersonalized'][action.payload] + color.value * order.base;
            break;
          }
        default:
          state.subtotal = 0
          break;
      }
    },
    getTotal: (state) =>
    {
      state.total = state.subtotal + state.tax - (isNaN(state.discount.totalDiscounted) ? 0 : state.discount.totalDiscounted)
    },
    getItemized: (state, action: PayloadAction<Currency>) =>
    {/*
    Type?: { description: string, value: number }
  color?: { description: string, value: number },
  extra?: { description: string, value: number },
  logo?: { description: string, value: number },
  cards?: { description: string, value: number },
  dashboard?: { description: string, value: number },
  personalized?: { description: string, value: number },
  tax?: { description: string, value: number }
} */
      switch (state.order.type)
      {
        case PurchaseType.Black:
          {
            const order = state.order as Black;
            const color = getColorData(order.color, action.payload);

            state.itemized = { type: { value: curr['blackBase'][action.payload], description: 'BLACK Card' }, logo: { description: 'Logo', value: order.logoAdd ? curr['blackLogo'][action.payload] : 0 }, color: { value: color.value, description: color.description }, extra: { description: `Extra Card X ${state.order.extra}`, value: state.order.extra * curr['blackExtra'][action.payload] } }
            break;
          }
        case PurchaseType.Elite:
          {
            const order = state.order as Elite;
            const color = getColorData(order.color, action.payload);
            state.itemized = { type: { value: curr['eliteBaseUnit'][action.payload] * order.base, description: `ELITE Card X ${order.base}` }, portal: { description: 'ELITE Portal', value: (order.base < 8 ? curr['eliteQuantityBase'][action.payload] - order.base * curr['eliteQuantityDiscount'][action.payload] : 0) }, personalized: { description: `Personalized Card X ${order.personalized}`, value: order.personalized * curr['elitePersonalized'][action.payload] }, extra: { description: `Extra Set X ${order.extra}`, value: order.extra > 0 ? order.base * order.extra * curr['eliteExtra'][action.payload] : 0 }, color: { value: color.value * order.base, description: `${color.description} X ${order.base}` } }
            break;
          }
        case PurchaseType.ReplacementBlack:
          {
            state.itemized = { type: { value: curr['blackReplacement'][action.payload] * state.order.extra, description: `BLACK Card Replacement X ${state.order.extra}` } }
            break;
          }
        case PurchaseType.ReplacementElite:
          {
            const order = state.order as Elite;
            const extra = order.npCard.length > 0 ? order.npCard.reduce((p, c, i) => { return { extra: p.extra! + c.extra! } as PCard }).extra : 0;
            state.itemized = {
              type: { value: curr['eliteReplacement'][action.payload] * extra!, description: `ELITE Card Replacement X ${extra}` }
            }
            break;
          }
        case PurchaseType.NewMember:
          {
            const order = state.order as Elite;
            const color = getColorData(order.color, action.payload);
            state.itemized = { type: { value: curr['eliteBaseUnit'][action.payload] * order.base, description: `ELITE Card X ${order.base}` }, personalized: { description: `Personalized Card X ${order.personalized}`, value: order.personalized * curr['elitePersonalized'][action.payload] }, color: { value: color.value * order.base, description: `${color.description} X ${order.base}` } }
            break;
          }
        default:
          {
            state.itemized = {
            }
            break;
          }
      }
    }
  }
});


export const {
  setOrder,
  setDiscount,
  getSubTotal,
  getTotal,
  setDetails,
  getTax,
  getItemized

} = cartSlice.actions;

export default cartSlice.reducer;
